<template>
  <div class="socialBadge">
    <button
      class="socialBadge--button shadow-xs"
      @click="toggleClass"
      v-bind:class="{ hide: isActive }"
    >
      <i class="icon-share-2"></i>
      {{ $t('Share') }}
    </button>
    <ul
      class="socialBadge--hiddenContent shadow-xs"
      v-bind:class="{ open: isActive }"
    >
      <li
        v-for="socialMediaItem in socialMediaLink"
        :key="socialMediaItem.name"
      >
        <button
          type="button"
          @click="copyLinkFunction()"
          v-if="socialMediaItem.hasCopyLink"
        >
          <i :class="socialMediaItem.icon"></i>
          <span class="d-none">{{ socialMediaItem.name }}</span>
        </button>

        <a :href="getShareLink(socialMediaItem.src)" v-else target="_blank">
          <i :class="socialMediaItem.icon"></i>
          <span class="d-none">{{ socialMediaItem.name }}</span>
        </a>
      </li>
      <li class="copyLink">
        <small
          class="copyLink--alert rounded-pill bg-hazelgreen text-white p-1 px-2"
          v-show="showCopyAlert"
          >{{ $t('Link wurde in die Zwischenablage kopiert') }}</small
        >
        <button class="copy" @click="copyLink">
          <i class="icon-link"></i>
        </button>
        <input
          @focus="$event.target.select()"
          ref="copyinput"
          readonly
          class="copyLink--copyinput"
          :value="getCurrentLink()"
        />
      </li>
      <li>
        <button class="close" @click="toggleClass">
          <i class="icon-x"></i>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SocialBadge',
  data() {
    return {
      isActive: false,
      showCopyAlert: false,

      hasCopyLink: {
        type: Boolean,
        default: false,
      },

      socialMediaLink: [
        {
          src: 'https://twitter.com/intent/tweet?text=&url=',
          name: 'Twitter',
          icon: 'social-icon-twitter',
        },
        {
          src: 'https://www.facebook.com/sharer/sharer.php?u=',
          name: 'Facebook',
          icon: 'social-icon-facebook',
        },
        {
          src: 'https://www.linkedin.com/sharing/share-offsite/?url=',
          name: 'Linked In',
          icon: 'social-icon-linkedIn',
        },
        {
          src: 'https://web.whatsapp.com/send?text=',
          name: 'Whats App',
          icon: 'social-icon-whatsapp',
        },
        {
          src: 'mailto:?body=',
          name: 'Als Mail weiterleiten',
          icon: 'icon-mail',
        },
      ],
    };
  },
  watch: {
    showCopyAlert() {
      setTimeout(() => {
        this.setCopyAlert(false);
      }, 2000);
    },
  },

  methods: {
    toggleClass: function () {
      this.isActive = !this.isActive;
    },

    getCurrentLink() {
      return window.location.origin + this.$router.currentRoute.path;
    },

    getShareLink(linkBase) {
      return linkBase + this.getCurrentLink();
    },
    copyLink() {
      this.setCopyAlert(true);
      this.$refs.copyinput.focus();
      document.execCommand('copy');
    },
    setCopyAlert(val) {
      this.showCopyAlert = val;
    },
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';

.socialBadge {
  position: fixed;
  bottom: $spacer-5;
  z-index: 999;
  right: calc(0.5 * var(--bs-gutter-x));

  @include media-breakpoint-up(lg) {
    position: sticky;
    top: 50%;
    right: 0;
    bottom: inherit;
    margin-top: 2rem;
  }

  &--button {
    padding: 0.875rem 11.1875rem;
    text-transform: uppercase;
    border-radius: 3.75rem;
    display: flex;
    align-items: center;
    background: $white;
    color: $hazelgreen;
    max-height: 2.75rem;
    margin-left: auto;
    visibility: visible;
    opacity: 1;
    transition: $transition-base;

    i {
      margin-right: 0.6875rem;

      &::before {
        font-size: 1.5rem;
        height: 100%;
        display: flex;
        color: $hazelgreen;
      }
    }

    &.hide {
      visibility: hidden;
      opacity: 0;
    }
  }

  &--hiddenContent {
    display: flex;
    visibility: hidden;
    opacity: 0;
    transition: $transition-base;
    padding: 0.5rem 1.125rem;
    border-radius: 6.25rem;
    margin: 0 0 0 auto;
    list-style: none;
    align-items: center;
    width: max-content;
    position: absolute;
    top: 0;
    right: 0;
    background: $white;

    &.open {
      display: flex;
      visibility: visible;
      opacity: 1;
    }

    li {
      a,
      button {
        text-decoration: none;
        padding: 0 0.5rem;
        display: flex;

        i {
          color: $anthracite;
          transition: $transition-base;
          max-height: 1.75rem;

          &::before {
            font-size: 1.75rem;
          }
        }

        span {
          display: none;
        }

        &:hover {
          i {
            &::before {
              color: $hazelgreen;
            }
          }
        }

        @include media-breakpoint-down(sm) {
          padding: 0 0.3125rem;
        }
      }

      .close {
        padding-left: 1.5rem;

        i {
          display: flex;

          &::before {
            font-size: 1.2rem;
          }
        }

        @include media-breakpoint-down(sm) {
          padding-left: 0.625rem;
        }
      }

      &:first-child {
        display: flex;

        a {
          padding-left: 0;
        }
      }
    }
  }
  .copyLink {
    position: relative;
    &--copyinput {
      opacity: 0;
      position: absolute;
      z-index: -100;
      left: 0;
      top: 0;
      user-select: none;
    }
    &--alert {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
    }
  }
}

button.close {
  padding: 0;
}
</style>
