<template>
  <div
    :class="getContainerClass(element)"
    v-if="!docCheckValidation"
    :data-cd="showDocCheckLogin"
    :data-cdv="docCheckValidation"
  >
    <header
      class="mb-7"
      v-if="element.content.header && element.content.headerLayout"
      v-html="getHeadline(element.content.header, element.content.headerLayout)"
    />

    <div
      v-if="
        (getElementsOf(0, 0) || getElementsOf(0, 1)) &&
        element.content.structure.layout == 1
      "
      class="row"
    >
      <div class="col-12" :class="getColClasses(element, 2, 1)">
        <div
          class="card shadow rounded-3 p-2 doc-check-styling"
          :class="
            element.appearance.frameClass == 'bg-sand' ? 'bg-white' : 'bg-sand'
          "
          v-if="element.appearance.subLayout == '50-50-white-box'"
        >
          <DynamicElement
            v-for="ce in getElementsOf(0, 0)"
            :key="ce.id"
            :config="getConfig()"
            :element="ce"
          />
        </div>
        <template v-else>
          <DynamicElement
            v-for="ce in getElementsOf(0, 0)"
            :key="ce.id"
            :config="getConfig()"
            :element="ce"
          />
        </template>
      </div>
      <div class="col-12" :class="getColClasses(element, 2, 2)">
        <div
          class="card shadow rounded-3 p-2"
          :class="
            element.appearance.frameClass == 'bg-sand' ? 'bg-white' : 'bg-sand'
          "
          v-if="element.appearance.subLayout == '50-50-white-box'"
        >
          <DynamicElement
            v-for="ce in getElementsOf(0, 1)"
            :key="ce.id"
            :config="getConfig()"
            :element="ce"
          />
        </div>
        <template v-else>
          <DynamicElement
            v-for="ce in getElementsOf(0, 1)"
            :key="ce.id"
            :config="getConfig()"
            :element="ce"
          />
        </template>
      </div>
    </div>
    <div
      v-if="
        (getElementsOf(0, 0) || getElementsOf(0, 1) || getElementsOf(0, 2)) &&
        element.content.structure.layout == 2
      "
      class="row"
    >
      <div class="col-12 col-lg-4">
        <DynamicElement
          v-for="ce in getElementsOf(0, 0)"
          :key="ce.id"
          :config="getConfig()"
          :element="ce"
        />
      </div>
      <div class="col-12 col-lg-4">
        <DynamicElement
          v-for="ce in getElementsOf(0, 1)"
          :key="ce.id"
          :config="getConfig()"
          :element="ce"
        />
      </div>
      <div class="col-12 col-lg-4">
        <DynamicElement
          v-for="ce in getElementsOf(0, 2)"
          :key="ce.id"
          :config="getConfig()"
          :element="ce"
        />
      </div>
    </div>
    <div
      v-if="
        (getElementsOf(0, 0) ||
          getElementsOf(0, 1) ||
          getElementsOf(0, 2) ||
          getElementsOf(0, 3)) &&
        element.content.structure.layout == 3
      "
      class="row"
    >
      <div class="col-12 col-md-6 col-lg-3">
        <DynamicElement
          v-for="ce in getElementsOf(0, 0)"
          :key="ce.id"
          :config="getConfig()"
          :element="ce"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <DynamicElement
          v-for="ce in getElementsOf(0, 1)"
          :key="ce.id"
          :config="getConfig()"
          :element="ce"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <DynamicElement
          v-for="ce in getElementsOf(0, 2)"
          :key="ce.id"
          :config="getConfig()"
          :element="ce"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <DynamicElement
          v-for="ce in getElementsOf(0, 3)"
          :key="ce.id"
          :config="getConfig()"
          :element="ce"
        />
      </div>
    </div>
    <template
      v-if="getElementsOf(0, 0) && element.content.structure.layout == 4"
    >
      <Slider3Col
        :slider="getElementsOf(0, 0)"
        sliderType="icon_with_text"
        v-if="appearance.layout == 'grid-slider'"
      />
      <Tabs v-if="appearance.layout == 'tabs'" :data="getElementsOf(0, 0)" />
      <!-- <pre>{{getElementsOf(0,0)}}</pre> -->
    </template>
  </div>
</template>

<script>
// 1 = 2 col
// 2 = 3 col
// 3 = 4 col
// 4 = slider
import DynamicElement from '~/components/DynamicElement.vue';
import Slider3Col from '~/components/Slider3Col.vue';
import Tabs from './Tabs.vue';
import { useMainNavStore } from '../stores/mainNavStore';
import { computed } from '@nuxtjs/composition-api';
export default {
  name: 'GridElement',
  components: {
    DynamicElement,
    Slider3Col,
    Tabs,
  },
  props: {
    element: Object,
    appearance: Object,
    config: {
      type: Object,
      default: () => {
        return {
          container: true,
        };
      },
    },
  },
  setup() {
    const mainNavStore = useMainNavStore();
    const showDocCheckLogin = computed(() => mainNavStore.showDocCheckLogin);
    const doccheckVerified = computed(() => mainNavStore.doccheckVerified);
    return {
      showDocCheckLogin: showDocCheckLogin.value,
      doccheckVerified: doccheckVerified.value,
    };
  },
  computed: {
    docCheckValidation() {
      if (this.element?.id === 15303 && this.doccheckVerified) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getContainerClass(element) {
      if (
        (element?.appearance?.layout == 'default' ||
          element?.appearance?.layout == '0' ||
          element?.appearance?.layout == 'grid-slider') &&
        this.config.container
      ) {
        return 'container';
      } else {
        return 'w-100';
      }
    },
    checkFullsize(element) {
      if (element?.appearance?.layout == 'container-full') {
        return ' p-0 m-0';
      } else {
        return '';
      }
    },
    getConfig() {
      return { container: false };
    },
    getHeadline(headline, headerLayout) {
      return '<h' + headerLayout + '>' + headline + '</h' + headerLayout + '>';
    },
    getElementsOf(row, col) {
      const element = this.element.content.structure;
      if (element.rows[row]?.columns[col]) {
        return element?.rows[row]?.columns[col]?.elements;
      } else {
        console.warn('Col ' + col + ' leer in ID ' + this.element.id);
        return false;
      }
    },
    getColClasses(element, layout, col) {
      if (
        (element?.appearance?.subLayout === '33-66' &&
          layout === 2 &&
          col === 1) ||
        (element?.appearance?.subLayout === '66-33' &&
          layout === 2 &&
          col === 2)
      ) {
        return 'col-md-6 col-lg-5' + this.checkFullsize(element);
      }
      if (
        (element?.appearance?.subLayout === '33-66' &&
          layout === 2 &&
          col === 2) ||
        (element?.appearance?.subLayout === '66-33' &&
          layout === 2 &&
          col === 1)
      ) {
        return 'col-md-6 col-lg-7' + this.checkFullsize(element);
      }
      return 'col-md-6' + this.checkFullsize(element);
    },
  },
};
</script>

<style lang="scss">
section.structured_content {
  overflow: hidden;
}

.doc-check-styling {
  h4 {
    font-size: 1rem;
    line-height: 170%;
  }
  iframe {
    margin: 0 auto;
    display: block;
  }
}
</style>
