<template>
  <article class="content mt-lg-4">
    <div class="col-12">
      <div class="blog-meta d-lg-flex mb-lg-6">
        <div class="published mb-5 mb-lg-0">
          <span class="d-block text-preset-details-small mb-2">{{
            $t('Published')
          }}</span>
          <span class="d-block body-2-regular">{{ date }}</span>
        </div>
        <div class="author mb-5 mb-lg-0">
          <span class="d-block text-preset-details-small mb-2">{{
            $tc('Authors', authors.length)
          }}</span>
          <span class="description d-block body-2-regular">
            <template v-if="authors.length">
              <span v-for="(author, i) in authors" :key="author.title">
                <T3Link :href="author.detailPage" v-if="author.detailPage">
                  <template #content>
                    {{ author.firstname }} {{ author.lastname
                    }}<template v-if="i + 1 < authors.length">, </template>
                  </template>
                </T3Link>
                <template v-else>
                  {{ author.firstname }} {{ author.lastname
                  }}<template v-if="i + 1 < authors.length">, </template>
                </template>
              </span>
            </template>
            <template v-else> TISSO Naturprodukte GmbH </template>
          </span>
        </div>
        <div class="read mb-5 mb-lg-0">
          <span class="d-block text-preset-details-small mb-2">{{
            $t('Read time')
          }}</span>
          <span class="d-block body-2-regular">{{ readTime }}</span>
        </div>
      </div>
      <h1 class="h2">
        {{ title }}
      </h1>
    </div>
    <span v-html="getBodytext()" />
  </article>
</template>

<script>
import RatioImage from './RatioImage.vue';
import GridElement from '~/components/GridElement.vue';
import DynamicElement from '~/components/DynamicElement.vue';
import T3Link from '~/components/General/T3Link.vue';
export default {
  name: 'MainContentBlogDetail',
  components: {
    T3Link,
    RatioImage,
    GridElement,
    DynamicElement,
  },
  props: {
    bodytext: String,
    date: Date | String,
    content: Array,
    title: String,
    readTime: String,
    authors: Array,
  },
  methods: {
    getBodytext() {
      const bodytext = this.bodytext;
      return bodytext.replace(
        'src="fileadmin',
        'src="' + this.basePath + '/fileadmin',
      );
    },
  },
  data() {
    return {
      basePath: process.env.T3_API_BASE,
    };
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import 'assets/styles/misc/vars';
.content {
  h4,
  .h4 {
    margin-bottom: var(--spacer-5);
  }
  .d-grid {
    gap: var(--spacer-2);
  }
}

@include media-breakpoint-up(md) {
  .d-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include media-breakpoint-up(lg) {
  .blog-meta {
    gap: var(--spacer-5);
    .published,
    .read {
      flex: 0 0 max-content;
    }
  }
  .ratio {
    --bs-aspect-ratio: 30.75%;
  }
}
</style>
