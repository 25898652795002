<template>
  <div
    tabindex="-1"
    ref="swipeArea"
    @keydown.esc="closeModal"
    @keydown.left.prevent="slideBack"
    @keydown.right.prevent="slideNext"
    :class="{
      'enlarge-image-modal': true,
      'enlarge-image-modal--show': modalIsOpen,
    }"
  >
    <button
      class="enlarge-image-modal__close-cursor"
      @click.prevent="closeModal"
    >
      &times;
    </button>

    <div
      @click.prevent="closeModal"
      :class="{
        'enlarge-image-modal__img-wrap': true,
        'enlarge-image-modal__single-image': sourceImg,
      }"
    >
      <img v-lazy-load :data-src="sourceImageComputed" />
    </div>

    <div v-if="slidesImg" class="enlarge-image-modal__arrows-container">
      <button
        @click.prevent="slideBack"
        class="enlarge-image-modal__arrow arrow-prev"
        type="button"
      >
        <i class="icon-arrow-left"></i>
      </button>

      <button
        @click.prevent="slideNext"
        class="enlarge-image-modal__arrow arrow-next"
        type="button"
      >
        <i class="icon-arrow-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
import {
  ref,
  watch,
  nextTick,
  onMounted,
  computed,
} from '@nuxtjs/composition-api';

export default {
  name: 'EnlargeImgModal',
  props: {
    slidesImg: Array,
    slideIndex: Number,
    sourceImg: String,
    modalIsOpen: Boolean,
  },

  setup(props, context) {
    const internSlideIndex = ref(0);
    const swipeArea = ref(null);
    let touchstartX = 0;
    let touchstartY = 0;
    let touchendX = 0;
    let touchendY = 0;
    const threshold = 50; // minimum distance required to register a swipe

    const handleSwipe = (direction) => {
      if (direction === 'right') {
        slideBack();
      } else {
        slideNext();
      }
    };

    const handleTouchStart = (event) => {
      touchstartX = event.changedTouches[0].screenX;
      touchstartY = event.changedTouches[0].screenY;
    };

    const handleTouchEnd = (event) => {
      touchendX = event.changedTouches[0].screenX;
      touchendY = event.changedTouches[0].screenY;
      const deltaX = touchendX - touchstartX;
      const deltaY = touchendY - touchstartY;
      if (Math.abs(deltaX) > threshold && Math.abs(deltaY) < threshold) {
        handleSwipe(deltaX > 0 ? 'right' : 'left');
      }
    };

    onMounted(() => {
      swipeArea.value.addEventListener('touchstart', handleTouchStart, false);
      swipeArea.value.addEventListener('touchend', handleTouchEnd, false);
    });

    const closeModal = () => {
      context.emit('onModalClose');
    };

    const slideBack = () => {
      if (props.slidesImg !== undefined) {
        internSlideIndex.value =
          internSlideIndex.value === 0
            ? props.slidesImg.length - 1
            : internSlideIndex.value - 1;
      }
    };

    const slideNext = () => {
      if (props.slidesImg !== undefined) {
        internSlideIndex.value =
          internSlideIndex.value === props.slidesImg.length - 1
            ? 0
            : internSlideIndex.value + 1;
      }
    };

    const sourceImageComputed = computed(() => {
      if (props.slidesImg !== undefined) {
        //Use original image size if it's coming from T3
        if (props.slidesImg[internSlideIndex.value].originalUrl) {
          return props.slidesImg[internSlideIndex.value].originalUrl;
        } else {
          return props.slidesImg[internSlideIndex.value].publicUrl;
        }
      }

      if (props.sourceImg !== undefined) {
        return props.sourceImg;
      }
    });

    watch(
      () => props.modalIsOpen,
      (newModalIsOpen) => {
        if (newModalIsOpen) {
          nextTick(() => {
            swipeArea.value.focus();
          });
          internSlideIndex.value = props.slideIndex;
        }
      },
    );

    return {
      internSlideIndex,
      closeModal,
      slideBack,
      slideNext,
      swipeArea,
      sourceImageComputed,
    };
  },
};
</script>

<style lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.enlarge-image-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  visibility: hidden;
  text-align: center;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 888;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  user-select: none;

  &.enlarge-image-modal--show {
    visibility: visible;
    overflow: hidden;
  }

  .enlarge-image-modal__img-wrap {
    width: 80vw;
    height: 100vh;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  //mobile enlarge.
  @media (max-width: 870px) {
    .enlarge-image-modal__img-wrap {
      width: 93vw;
      height: 100vh;
      zoom: 0.8;

      &.enlarge-image-modal__single-image {
        zoom: 0.4;
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (max-width: 670px) {
    .enlarge-image-modal__img-wrap {
      width: 100%;
      height: 100% !important;
      zoom: 0.8;
    }
  }

  .enlarge-image-modal__close-cursor {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 64px;
    height: 64px;
    color: #6f7953;
    cursor: pointer;
    font-size: 3.5rem;
    z-index: 9999;
  }

  .enlarge-image-modal__arrows-container {
    position: unset;
    transform: unset;
    top: unset;
    width: unset;

    .enlarge-image-modal__arrow {
      font-size: 2.5rem;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      color: #6f7953;

      &.arrow-prev {
        left: 50px;
      }

      &.arrow-next {
        right: 50px;
      }
    }
  }

  @media (max-width: 670px) {
    .enlarge-image-modal__arrows-container {
      display: none;
    }
  }
}
</style>
