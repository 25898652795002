<template>
  <div class="tab">
    <header class="tab--header">
      <div class="container">
        <ul class="blanklist">
          <li
            v-for="tab in data"
            :key="tab.uid"
            :class="{ active: getClass(tab.content.header) }"
            @click="tabHandler(tab.content.header)"
          >
            {{ tab.content.header }}
            <i class="icon-chevron-down"></i>
          </li>
        </ul>
      </div>
    </header>

    <div class="container">
      <template v-for="tab in data">
        <div
          class="tab--content"
          v-if="getClass(tab.content.header)"
          :key="tab.header"
        >
          <header class="row" v-if="tab.header">
            <h3 class="text-center">
              {{ tab.header }}
            </h3>
          </header>
          <div class="row">
            <!-- <pre>{{tab.content.structure.rows[0].columns[0].elements}}</pre> -->

            <template
              v-for="ce in tab.content.structure.rows[0].columns[0].elements"
            >
              <section
                :class="ce.type"
                :id="getID(ce.id)"
                v-if="ce"
                :key="ce.id"
              >
                <GridElement
                  :appearance="ce.appearance"
                  :key="ce.id"
                  :element="ce"
                  v-if="ce.type == 'structured_content'"
                />
                <DynamicElement :key="ce.id" :element="ce" v-else />
              </section>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { replaceAll, tabTranslator } from '~/composables/utils/functions';
export default {
  name: 'Tabs',

  data() {
    return {
      replaceAll,
      activeTab: '',
    };
  },

  components: {
    DynamicElement: () => import('~/components/DynamicElement'),
    GridElement: () => import('~/components/GridElement'),
  },

  props: {
    data: Object | Array,
  },

  methods: {
    getConfig() {
      return { container: false };
    },
    tabHandler(link) {
      this.activeTab = tabTranslator(link);
      if (history.pushState) {
        history.pushState(null, '', `?tab=${tabTranslator(link)}`);
      }
    },
    getClass(tab) {
      return this.activeTab === tabTranslator(tab);
    },
    getID(id) {
      if (id) {
        return 'c' + id;
      } else {
        return '';
      }
    },
  },
  async created() {
    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab;
    } else {
      if (this.data.length) {
        this.activeTab = tabTranslator(this.data[0]?.content?.header) || '';
      }
    }
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';

.tab {
  &--header {
    position: relative;
    width: calc(100% + 2rem);
    left: -1rem;

    @include media-breakpoint-up(lg) {
      background: $white;
      padding-top: 2rem;
    }

    ul {
      li {
        background: $sand;
        border: 1px solid $bone-83;
        border-bottom: 0;
        padding: 1.5rem;
        text-transform: uppercase;
        position: relative;

        &.active,
        &:hover {
          background: $hazelgreen-70;
          border-color: $hazelgreen-70;
          color: $hazelgreen;
        }

        &:last-child {
          border-bottom: 1px solid $bone-83;
        }

        [class*='icon-'] {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 1.5rem;

          &::before {
            font-size: 1.5rem;
          }
        }

        &.active {
          [class*='icon-'] {
            &::before {
              transform: rotate(180deg);
            }
          }
        }
      }

      @include media-breakpoint-up(lg) {
        display: flex;
        margin: 0 -0.125rem;

        li {
          padding: 1rem 0;
          width: 33.3%;
          max-width: 33.3%;
          margin: 0 0.125rem;
          border-radius: 6.25rem 6.25rem 0 0;
          text-align: center;
          cursor: pointer;
          transition: $transition-base;
          border: 1px solid $bone-83;

          [class*='icon-'] {
            display: none;
          }
        }
      }
    }
  }

  &--content {
    padding: 4rem 0 0;

    @include media-breakpoint-up(lg) {
      padding: 5rem 0 0;
    }
  }
}

.tab {
  margin-top: -5rem;

  li {
    position: relative;
    a {
      position: static;
      &:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        border-radius: 6.25rem 6.25rem 0 0;
        z-index: 1;
      }
    }
  }
}
</style>
