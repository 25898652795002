<template ref="blogcontent">
  <div class="blog-page" ref="blogcontent">
    <div class="progress shadow">
      <div
        class="filled-bar"
        :style="{ transform: `translate3d(-${(1 - progress) * 100}%, 0, 0)` }"
      ></div>
    </div>
    <div class="bg-sand pb-2">
      <HeroHeaderBlogDetail
        :title="data.detail.title"
        :date="date(data.detail.datetime)"
        :authors="data.detail.authors"
        :readTime="data.detail.readTime"
        :headerImage="getHeaderImage(data.detail)"
        v-if="getHeaderImage(data.detail)"
      />

      <div class="container">
        <div class="row justify-content-lg-between mt-4">
          <div class="col-12 col-lg-7">
            <MainContentBlogDetail
              :bodytext="data.detail.bodytext"
              :title="data.detail.title"
              :date="date(data.detail.datetime)"
              :authors="data.detail.authors"
              :readTime="data.detail.readTime"
            />
          </div>
          <div
            class="col-12 col-lg-4 border-start border-1 border-gray py-2 py-lg-4 ps-lg-4"
          >
            <BlogSidebar
              :relatedNews="latest"
              :currentPostId="data.detail.uid"
              :bodytext="data.detail.bodytext"
            />
            <client-only>
              <SocialBadge />
            </client-only>
          </div>
        </div>
      </div>
    </div>

    <template v-for="ce in data.contentElements">
      <section
        :class="ce.type + getBg(ce) + getSpacing(ce)"
        :id="getID(ce.id)"
        v-if="ce"
        :key="ce.id"
      >
        <GridElement
          :key="ce.id"
          :element="ce"
          v-if="ce.type == 'structured_content'"
          :config="{ container: true }"
        ></GridElement>
        <DynamicElement
          :key="ce.id"
          :element="ce"
          :config="{ container: true }"
          v-else
        ></DynamicElement>
      </section>
    </template>

    <section class="bg-sand py-5 my-0">
      <div class="container">
        <div class="modified my-7 mb-lg-10">
          <span class="d-block text-preset-details-small mb-2">{{
            $t('Zuletzt aktualisiert')
          }}</span>
          <span class="d-block body-2-regular">{{
            date(data.detail.tstamp)
          }}</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import GridElement from '~/components/GridElement.vue';
import DynamicElement from '~/components/DynamicElement.vue';
import Breadcrumb from '../components/Meta/Breadcrumb.vue';
import HeroHeaderBlogDetail from '~/components/HeroHeaderBlogDetail.vue';
import MainContentBlogDetail from '../components/MainContentBlogDetail.vue';
import BlogSidebar from '~/components/BlogSidebar';
import TextMediaTeaser from '~/components/TextMediaTeaser.vue';
import RatioImage from '~/components/RatioImage.vue';
import TextContentBlock from '~/components/TextContentBlock.vue';
import SocialBadge from '~/components/SocialBadge';
import RelatedTopics from '~/components/RelatedTopics';
import FullWithTwoCols from '~/layouts/FullWithTwoCols.vue';
export default {
  name: 'BlogDetailPage',
  components: {
    Breadcrumb,
    HeroHeaderBlogDetail,
    MainContentBlogDetail,
    TextMediaTeaser,
    BlogSidebar,
    RatioImage,
    TextContentBlock,
    SocialBadge,
    RelatedTopics,
    FullWithTwoCols,
    GridElement,
    DynamicElement,
  },
  props: {
    data: Object,
    latest: Array,
  },
  data() {
    return {
      progress: 0,
      config: {
        container: true,
      },
    };
  },
  methods: {
    getBg(element) {
      if (element?.content?.shortcut) {
        element = element?.content?.shortcut[0];
      }
      if (this.config.container && element?.appearance) {
        if (element?.appearance?.frameClass !== 'default') {
          if (element?.appearance?.subLayout) {
            return (
              ' ' +
              element?.appearance?.frameClass +
              ' subLayout' +
              element?.appearance?.subLayout
            );
          } else {
            return ' ' + element?.appearance?.frameClass;
          }
        } else {
          return '';
        }
      }
    },
    getSpacing(element) {
      if (element?.content?.shortcut) {
        element = element?.content?.shortcut[0];
      }
      let spaceBefore = '';
      let spaceAfter = '';
      let frameClass = '';
      if (element?.appearance) {
        spaceBefore = element?.appearance?.spaceBefore;
        spaceAfter = element?.appearance?.spaceAfter;
        frameClass = element?.appearance?.frameClass;
      }
      if (!spaceBefore) {
        spaceBefore = '10';
      }
      if (!spaceAfter) {
        spaceAfter = '10';
      }
      if (frameClass == 'default' || frameClass == '') {
        return ' mt-' + spaceBefore + ' mb-' + spaceAfter;
      } else {
        return ' pt-' + spaceBefore + ' pb-' + spaceAfter;
      }
    },
    getID(id) {
      if (id) {
        return 'c' + id;
      } else {
        return '';
      }
    },
    date(date) {
      // TODO: this.$i18n locale
      const currentLocale = 'de-DE';
      const newDate = new Date(date * 1000);
      return newDate.toLocaleDateString(currentLocale);
    },

    getHeaderImage(object) {
      return object?.media[0]?.images?.detailViewImage?.publicUrl;
    },

    onScroll() {
      let pageHeaderHeight = document.querySelector('.pageHeader');
      const progress =
        window.scrollY /
        (this.$refs.blogcontent.scrollHeight +
          this.$refs.blogcontent.offsetTop -
          pageHeaderHeight.offsetHeight -
          window.innerHeight);

      if (window.scrollY > pageHeaderHeight.offsetHeight) {
        pageHeaderHeight.classList.add('not-sticky');
      } else {
        pageHeaderHeight.classList.remove('not-sticky');
      }

      if (progress < this.progress) {
        pageHeaderHeight.classList.add('scroll-up');
      } else {
        pageHeaderHeight.classList.remove('scroll-up');
      }

      //console.log(progress);
      if (progress > 1) {
        this.progress = 1;
      } else if (progress < 0) {
        this.progress = 0;
      } else {
        this.progress = progress;
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed() {
    let pageHeader = document.querySelector('.pageHeader');
    pageHeader?.classList.remove('not-sticky');

    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';
html {
  max-width: 100vw;
  overflow-x: hidden;
}
.pageHeader {
  transition: all 0.2s ease;

  &.not-sticky {
    transform: translate(0, -100%);
    &.scroll-up {
      transform: translate(0, 0);
    }
  }
}
.progress {
  position: fixed;
  top: 0;
  left: -2.5%;
  height: 0.5rem;
  width: 105%;
  z-index: 9992;
  background: #fff;
}
.filled-bar {
  background: $hazelgreen;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.5rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
</style>
